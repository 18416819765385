<template>
    <div class="columns is-mobile is-multiline">
        <div class="column is-full-mobile is-half-tablet">
            <div class="field">
                <label class="label">{{ i18n('Description') }}</label>
                <div class="control">
                    <textarea class="textarea" :placeholder="i18n('description')"
                              v-model="post.description" rows="3"></textarea>
                </div>
            </div>
        </div>
        <input type="hidden" name="city" v-model="post.location">
        <div class="column is-full-mobile">
            <div class="field">
                <label class="label">{{ i18n('Choose an File') }}</label>
                <div class="control">
                    <label class="file-label">
                        <input class="file-input"
                            type="file"
                            accept=".webp, .jpg, .jpeg, .avif, .png, .svg"
                            @change="handleFileUpload">
                        <span class="file-cta">
                            <span class="file-label">
                                {{ i18n('Choose an File') }}...
                            </span>
                        </span>
                        <span class="file-name">
                            {{ fileName || i18n('No file chosen') }}
                        </span>
                    </label>
                </div>
            </div>
        </div>
        <div class="column is-full-mobile is-full-tablet">
            <a class="button-alignment" @click="fetchSave">
                <button class="button butt-save">
                    {{ i18n('Save') }}
                    <i class="i-icon fas fa-check"/>
                </button>
            </a>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Create',

    inject: ['http', 'i18n', 'errorHandler', 'route'],

    data: () => ({
        post: {
            title: null,
            description: null,
        },
        fileName: '',
    }),
    computed: {
        canGeolocate() {
            return !!navigator.geolocation;
        },
    },
    created() {
        if (this.canGeolocate) {
            this.getLocation();
        }
    },
    methods: {
        handleFileUpload(event) {
            // eslint-disable-next-line prefer-destructuring
            this.post.file = event.target.files[0];
            this.fileName = event.target.files[0].name;
        },

        fetchSave() {
            const formData = new FormData();
            formData.append('file', this.post.file);
            formData.append('description', this.post.description);
            formData.append('location', this.post.location);
            const headers = { 'Content-Type': 'multipart/form-data' };
            this.http.post(this.route('posts.store'), formData,
                { headers })
                .then(() => {
                    this.$router.push('/dashboard');
                }).catch(this.errorHandler);
        },
        async getLocation() {
            let errorMessage = '';
            if (navigator.geolocation) {
                try {
                    const position = await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(resolve, reject);
                    });
                    const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${position.coords.latitude},${position.coords.longitude}&key=${process.env.VUE_APP_GOOGLE_MAPS_KEY}`);
                    const data = await response.json();
                    if (data.results.length > 0) {
                        const addressComponents = data.results[0].address_components;
                        const city = addressComponents.find(component => component.types.includes('locality'));
                        this.post.location = city ? city.long_name : '';
                    }
                } catch (error) {
                    // eslint-disable-next-line no-unused-vars
                    errorMessage = error;
                }
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import '../../../sass/enso.scss';
.butt-save{
    background-color: $limeGreen;
    border-color: transparent;
    color: $secondary !important;
}

.button-alignment{
    padding-top: 33px;
}

.styleInput{
    background-color: transparent;
    border-color: transparent;
}
</style>
